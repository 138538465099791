import React from "react"
import DocsLayout from "../components/docs-layout/docs-layout"
import ApiDocsContent from "../components/api-docs-content/api-docs-content"
import { SearchContextProvider } from "../contexts/search-context"
import { SelectedMenuContextProvider } from "../contexts/selected-menu-context"
import SEO from "../components/seo"

const ApiDocsTemplate = ({ pageContext }) => {
  return (
    <SelectedMenuContextProvider>
      <SearchContextProvider>
        <DocsLayout
          relativePath={""}
          urlParts={[]}
          menuModel={pageContext.menu}
          isApiDocs={true}>
          <SEO title={`Volterra API Docs`} isArticle={true} />
          <ApiDocsContent spec={pageContext.swaggerFile} />
        </DocsLayout>
      </SearchContextProvider>
    </SelectedMenuContextProvider>
  )
}

export default ApiDocsTemplate
