import React, { useState } from "react"
import { RedocStandalone } from "redoc"
import "./api-docs-content.scss"

const ApiDocsContent = ({ spec }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <>
      <div className={`loader ${isLoaded ? "loaded" : ""}`}></div>
      <RedocStandalone
        spec={spec}
        onLoaded={() => {
          setIsLoaded(true)
        }}
        options={{
          nativeScrollbars: true,
          disableSearch: true,
          hideLoading: true,
          theme: {
            breakpoints: {
              small: "50rem",
              medium: "85rem",
              large: "105rem",
            },
            colors: {
              tonalOffset: 0.3,
              primary: {
                main: "#4f73ff",
              },
              success: {
                main: "#35d068",
              },
              warning: {
                main: "#FFC400",
                contrastText: "#ffffff",
              },
              error: {
                main: "#f94627",
              },
              text: {
                primary: "#0f1e57",
                secondary: "#7a858e",
              },
              border: {
                dark: "rgba(0, 0, 0, 0.1)",
                light: "#ffffff",
              },
              responses: {
                success: {
                  color: ({ colors }) => colors.success.main,
                },
                error: {
                  color: ({ colors }) => colors.error.main,
                },
                redirect: {
                  color: "#ffa500",
                },
                info: {
                  color: "#87ceeb",
                },
              },
              http: {
                get: "#4f73ff",
                post: "#4f73ff",
                put: "#4f73ff",
                options: "#4f73ff",
                patch: "#4f73ff",
                delete: "#4f73ff",
                basic: "#4f73ff",
                link: "#4f73ff",
                head: "#4f73ff",
              },
            },
            schema: {
              linesColor: "#b9c7ff",
              defaultDetailsWidth: "75%",
              labelsTextSize: "0.9em",
              nestingSpacing: "1em",
              nestedBackground: "#f0f3f6",
              arrow: {
                size: "1.1em",
                color: theme => theme.colors.text.secondary,
              },
            },
            typography: {
              fontSize: "14px",
              lineHeight: "1.5em",
              fontWeightRegular: "400",
              fontWeightBold: "600",
              fontWeightLight: "300",
              fontFamily:
                "DM Sans, Helvetica Neue, Helvetica, Arial, sans-serif;, sans-serif",
              optimizeSpeed: true,
              headings: {
                fontFamily:
                  "DM Sans, Helvetica Neue, Helvetica, Arial, sans-serif;, sans-serif",
                fontWeight: "400",
                lineHeight: "1.6em",
              },
              code: {
                fontSize: "14px",
                fontFamily:
                  "SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace",
                color: "#262933",
                backgroundColor: "#fafbfc",
              },
              links: {
                color: ({ colors }) => colors.primary.main,
                visited: ({ typography }) => typography.links.color,
              },
            },
            sidebar: {
              display: "none",
              width: "0px",
              backgroundColor: "#fafbfc",
              textColor: "#262933",
              groupItems: {
                textTransform: "uppercase",
              },
              level1Items: {
                textTransform: "none",
              },
              arrow: {
                size: "1.5em",
                color: "#a0a6ad",
              },
            },
            rightPanel: {
              backgroundColor: "#262933",
              width: "40%",
              textColor: "#ffffff",
            },
            codeBlock: {
              backgroundColor: "#1f232c",
            },
          },
        }}
      />
    </>
  )
}

export default ApiDocsContent
